.Sample {
  width: 100%;
}

.Sample input,
.Sample button {
  font: inherit;
}

.Sample header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.Sample header h1 {
  font-size: inherit;
  margin: 0;
}

.Sample__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 0;
  padding: 10px;
}

.Sample__container > * > * {
  margin: 10px;
}

.Sample__container__content {
  display: flex;
  max-width: 100%;
  flex-basis: 420px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
}