#sidebar .accordion-body {
  padding: 0px;
  background-color: rgb(24, 3, 63); 
}

#sidebar .accordion-button  {
  background-color: white;
  color: #7848ff;
  font-weight: bold;
}

#sidebar .accordion-button[aria-expanded="true"]  {
  background-color: #7848ff;
  color: white;
}
